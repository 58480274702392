<template>
  <v-container class="contenedor-main">
    <v-row>
      <tool-bar-nav-list
        :items="[
          { items: filtroM, value: curso.materia, label: 'Materias' },
          { items: listaPeriodos, value: curso.periodo, label: 'Periodos' },
        ]"
        v-on:toggle="search($event)"
      ></tool-bar-nav-list>
    </v-row>
    <template>
      <v-row>
        <tool-bar-nav
          v-if="jsondata != null"
          :pageRow="{ value: pageLengthPer, type: 'estudiantesHeigth' }"
          :editColsBottom="{ badgeText: 'Editar cols' }"
          :dataColumns="{
            colsShow: columnasMostrar,
            colsList: listaColumnas,
            endpointEdit: 'editar',
            materia: curso.materia,
            periodo: curso.periodo,
          }"
          :refreshBottom="{ badgeText: 'Reestablecer cols' }"
          :copyRowBottom="{ badgeText: 'Copiar usuario' }"
          :exportBottom="{
            badgeText: 'Exportar tabla',

            nameDoc: 'Clientes',
          }"
          :addColsBottom="{
            badgeText: 'Agregar columnas nuevas',

            mat: curso.materia,
            per: curso.periodo,
          }"
          :deleteNewColsBottom="{
            badgeText: 'Eliminar columnas',

            colsExtra: listaColumnasE,
            mat: curso.materia,
            per: curso.periodo,
            jsonData: this.jsondata,
          }"
          :organiceCols="{
            badgeText: 'Organizar columnas',

            colsExtra: listaColumnasE,
            mat: curso.materia,
            per: curso.periodo,
          }"
          :editLabelCols="{
            badgeText: 'Cambiar nombres',
            colsExtra: listaColumnasE,
            mat: curso.materia,
            per: curso.periodo,
          }"
          :histBottom="{ show: rigthClick, data: historial }"
          :colorBottom="{ show: rigthClick }"
          :textoActionToolBar="{ text: textoActionToolBar }"
          :selectedRow="onSelected"
          :grid="{ idGrid: $refs.grid }"
          v-on:toggle="eventosEstudiantes($event)"
          v-on:texto-tool="eventosTextTool($event)"
          v-on:color-item="eventosColor($event, asignarColor)"
        >
        </tool-bar-nav>
        <v-container class="contenedor-tabla" v-show="mostrarTable == true">
          <vue-excel-editor
            v-if="mt"
            v-show="mostrarTable == true"
            ref="grid"
            :page="pageLengthPer"
            v-model="jsondata"
            filter-row
            :localized-label="myLabels"
            @select="onSelect"
            v-on:toggle-table="activeRigthClick($event)"
            @cell-focus="getLabel"
            :cell-style="metodoColorBack"
            :enterToSouth="enterAbajo"
            remember
          >
            <vue-excel-column
              field="Fecha de matrícula"
              label="Fecha de matricula"
              type="string"
              :validate="valid"
            />
            <vue-excel-column
              field="Nombre"
              label="Nombre"
              type="string"
              :validate="valid"
            />
            <vue-excel-column
              field="Usuario"
              label="Usuario"
              type="string"
              :validate="valid"
            />
            <vue-excel-column
              field="Contraseña"
              label="Contraseña"
              type="string"
              :validate="valid"
            />
            <vue-excel-column
              field="País"
              label="País"
              type="string"
              :validate="valid"
            />
            <vue-excel-column
              field="Servidor"
              label="Servidor"
              type="number"
              :validate="valid"
            />
            <vue-excel-column
              field="Moodle"
              label="Moodle"
              type="number"
              :validate="valid"
            />
            <vue-excel-column
              field="Correo"
              label="Correo"
              type="string"
              :validate="valid"
            />
            <vue-excel-column
              field="Linea"
              label="Linea"
              type="string"
              :validate="valid"
            />
            <vue-excel-column
              field="Contraseña del correo"
              label="Contraseña del correo"
              :change="onChange"
              type="string"
            />
            <!-- <vue-excel-column field="alert"    label="Alerta "    type="string"  /> -->
            <vue-excel-column
              field="Estado usuario"
              label="Estado usuario"
              type="select"
              :options="estadoU"
              :change="cambioEstadoU"
            />
            <vue-excel-column field="Respuesta" label="Respuesta" />
            <vue-excel-column
              field="LINK DRIVE"
              label="LINK DRIVE"
              type="number"
              :validate="valid"
            />
            <vue-excel-column
              field="CEAD"
              label="CEAD"
              type="string"
              :validate="valid"
            />
            <vue-excel-column
              field="Carrera"
              label="Carrera"
              :validate="valid"
            />
            <vue-excel-column field="Cédula" label="Cédula" :validate="valid" />
            <vue-excel-column
              field="Tutor"
              label="Tutor"
              type="string"
              :change="onChange"
            />
            <vue-excel-column
              field="Grupo"
              label="Grupo"
              type="string"
              :change="onChange"
            />
            <vue-excel-column
              field="Observaciones"
              label="Observaciones"
              type="string"
              :change="onChange"
            />
            <vue-excel-column
              field="REENVÍO"
              label="REENVÍO"
              type="string"
              :change="onChange"
            />
            <vue-excel-column
              v-for="field in newData"
              v-bind:key="field.key"
              :type="field.type"
              :field="field.field"
              :invisible="field.invisible"
              :label="field.label"
              :change="onChange"
            />
          </vue-excel-editor>
        </v-container>
        <v-footer color="transparent" class="col-12 ma-0 pa-0">
          <tool-bar-nav-footer
            :materia="curso.materia"
            :periodo="curso.periodo"
            v-on:edit-tabs-search="toolBarFooter"
          >
          </tool-bar-nav-footer>
        </v-footer>
      </v-row>
    </template>
  </v-container>
</template>


<script>
import firebase from "firebase";
import { host } from "./Tools/variables";
import axios from "axios";
// import listInfo from "./Tools/getListInfo";
import infoSelected from "./Tools/itemSelected";
import ToolBarNav from "./component/ToolBarNav.vue";
import ToolBarNavList from "./component/ToolBarNavList.vue";
import ToolBarNavFooter from "./component/ToolBarNavFooter.vue";
import data from "./Tools/data";

import tablesMixin from "../mixins/tablas";

export default {
  mixins: [tablesMixin],

  components: {
    ToolBarNavList,
    ToolBarNav,
    ToolBarNavFooter,
  },
  data() {
    return {
      textoActionToolBar: [],
      pageLengthPer: this.$store.state.estudiantesHeigth,
      newData: [],
      dialog: false,
      materias: "Ver materias",
      data: null,
      jsondata: [],
      dataCursos: [],
      filtroM: [],
      listaMaterias: [],
      listaPeriodos: [],
      listaCursos: [],

      mostrarTable: false,
      numeroCol: null,
      numeroRow: null,
      infoCli: [],
      editarColumnas: false,
      eliminarColumnas: false,
      listaColumnas: [],
      columnasMostrar: [],
      listaColumnasE: [],
      adminlevel: null,
      estadoU: [],
      mt: false,
      selectCol: null,
      onSelected: null,
      dataTiposA: [],
      curso: {
        materia: null,
        periodo: null,
      },
      // click derecho
      rigthClick: false,
      // color
      asignarColor: {
        name: null,
        color: null,
        idItem: null,
      },
      // tiene personalización de cols?
      haveCellsColor: true,
      // hitorial
      historial: {
        nombreCol: null,
        id: null,
      },
    };
  },

  watch: {
    curso: {
      deep: true,
      handler(a) {
        var obj = Object.values(a);
        var ok = obj.every((e) => e != null);
        if (ok) {
          this.buscar();
          document.title =
            "Estudiantes" +
            "/" +
            this.curso.materia +
            " - " +
            this.curso.periodo;
        } else {
          this.mostrarTable = false;
          this.$store.state.clientesFiltro = null;
        }
      },
    },
    // materia: function (v) {

    //   if (this.periodo && v) {
    //     document.title = "Estudiantes" + "/" + v + " - " + this.periodo;
    //     this.buscar();
    //   }
    //   if (v == null || v == "") {
    //     this.mostrarTable = false;
    //     this.$store.state.estudiantesFiltro = null;
    //   }
    // },
    // periodo: function (v) {
    //   if (v && this.materia) {
    //     document.title = "Estudiantes" + "/" + this.materia + " - " + v;
    //     this.buscar();
    //   }
    //   if (v == null || v == "") {
    //     this.mostrarTable = false;
    //     this.$store.state.estudiantesFiltro = null;
    //   }
    // },
    dataTiposA: function () {
      for (var f = 0; f < this.$store.state.tiposAlerta.length; f++) {
        this.estadoU.push(this.$store.state.tiposAlerta[f]["nombre"]);
      }
    },
  },

  async created() {
    if (this.$store.state.estudiantesFiltro != null) {
      var info = this.$store.state.estudiantesFiltro.split(" - ");
      this.curso.materia = info[0];
      this.curso.periodo = info[1];
    }
    this.dataTiposA = await data.dataList(
      "/tiposAlerta",
      "lista",
      "listar",
      "tiposAlerta"
    );
  },

  mounted() {
    document.title = "Estudiantes";
    this.cursosProfes();
  },

  methods: {
    blur() {
      this.$refs.grid.inputBoxBlur();
    },

    cursosProfes() {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "cursos_habilitados");
          axios.post(host + "/cursos", fd).then((result) => {
            let data = result.data;
            this.filtroM = data.listaMaterias;
            this.listaPeriodos = data.listaPeriodos;
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    async buscar() {
      this.$store.state.estudiantesFiltro =
        this.curso.materia + " - " + this.curso.periodo;
      this.textoActionToolBar = [];
      this.jsondata = [];
      this.columnasMostrar = [];
      this.mostrarTable = false;
      this.listaColumnas = [];
      this.mt = false;
      this.newData = [];
      await this.cargueColors();
      // this.waitData = false;
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "lista_estudiantes");
          fd.append("periodo", this.curso.periodo);
          fd.append("materia", this.curso.materia);
          axios
            .post(host + "/cursos", fd)
            .then((result) => {
              // this.waitData = true;
              let data = result.data;
              if (
                data.text ==
                "Error, no se puede encontrar el curso seleccionado"
              ) {
                this.buscando = false;
                this.$alert(
                  "Error, no se puede encontrar el curso seleccionado"
                );
              }
              if (data.text == "OK") {
                var dat = data.lista;
                // this.pageLength = this.$refs;
                for (let l = 0, len = dat.length; l < len; l++) {
                  // console.log(dat[l])
                  var valJson = Object.keys(dat[l]);
                  for (let k = 0, leng = valJson.length; k < leng; k++) {
                    if (
                      valJson[k].includes("%") ||
                      valJson[k].includes("Nota")
                    ) {
                      dat[l][valJson[k]] =
                        dat[l][valJson[k]] == null ? "" : dat[l][valJson[k]];
                    }
                  }
                  dat[l]["Fecha de matrícula"] = this.$moment(
                    dat[l]["Fecha de matrícula"]
                  ).format("YYYY/MM/DD - HH:mm:ss ");
                }
                this.jsondata = dat;
                this.mostrarTable = true;
                if (this.jsondata.length == 0) {
                  this.$alert("este curso no tiene estudiantes matriculados");
                } else {
                  this.listaColumnas = data.visibilidad;
                  this.listaColumnasE = data.dinamicas;
                  for (var k = 0; k < this.listaColumnas.length; k++) {
                    if (this.listaColumnas[k]["valor"] == true) {
                      this.columnasMostrar.push(
                        this.listaColumnas[k]["nombre"]
                      );
                    }
                  }
                  var info = this.jsondata[0];
                  this.cargueCol();

                  if (Object.keys(info).length > 44) {
                    this.newData = [];
                    // let invi = []
                    let iggg;
                    for (var i = 44; i < Object.keys(info).length; i++) {
                      for (var j = 0; j < this.listaColumnas.length; j++) {
                        if (
                          this.listaColumnas[j]["nombre"] ==
                          Object.keys(info)[i]
                        ) {
                          iggg = this.listaColumnas[j]["valor"];
                        }
                      }
                      var type = "string";
                      var field = Object.keys(info)[i];
                      if (
                        Object.keys(info)[i].includes("%") ||
                        Object.keys(info)[i].includes("Nota")
                      ) {
                        type = "number";
                        field =
                          Object.keys(info)[i] == null
                            ? 0
                            : Object.keys(info)[i];
                      }
                      // ACA DEBERIA EDITAR EL ORDEN
                      if (field != "undefined") {
                        this.newData.push({
                          label: Object.keys(info)[i],
                          field: field,
                          pos: i,
                          key: i,
                          invisible: !iggg,
                          type: type,
                        });
                      }
                    }
                  }
                }
              }
            })
            .catch(function (error) {
              this.$alert("Error " + error.message);
            });
          this.mt = true;
        });
    },

    onChange(newVal, oldVal, row, field) {
      this.textoActionToolBar.push("Guardando " + '"' + newVal + '"');
      var data;
      data = field.label;
      console.log(oldVal);
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "registrar_nota");
          fd.append("estudianteId", row._id);
          fd.append("periodo", this.curso.periodo);
          fd.append("materia", this.curso.materia);
          fd.append("campo", data);
          fd.append("nota", newVal.trim());
          axios.post(host + "/cursos", fd).then((result) => {
            var rem = this.textoActionToolBar.indexOf(
              "Guardando " + '"' + newVal + '"'
            );
            if (result.data.text != "OK") {
              this.$alert("Error al guardar la información de esta nota");
              this.textoActionToolBar.splice(rem, 1);
              this.textoActionToolBar.push(
                "Error al guardar " + '"' + newVal + '"'
              );
              return false;
            } else {
              this.textoActionToolBar.splice(rem, 1);
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    metodoColor(row, col) {
      var rojos = [
        "NO INGRESA (PLATAFORMA)",
        "NO INGRESA (CORREO)",
        "VERIFICACIÓN CORREO",
        "NO COINCIDE CORREO",
        "MAL MIGRADO",
        "NO LLEGA PROUNAD",
      ];
      var amarillos = ["NI1"];
      var morados = ["DPS"];
      var moradoNuevo = ["NUEVO"];
      var serverNulo = ["SERVER NULO"];
      var verde = ["SOL"];
      var naranja = ["NI2"];
      var azul = ["LAZARO"];

      switch (col.name) {
        case "Usuario":
          var rowItemLazaro = row.lazaro;
          if (rowItemLazaro == true) {
            return {
              "background-color": "#4285f4",
            };
          }
          break;
        case "Estado usuario":
          var rowItemEstadoU = row["Estado usuario"].trim();
          if (rojos.includes(rowItemEstadoU)) {
            return {
              "background-color": "#FF0000",
            };
          }
          if (amarillos.includes(rowItemEstadoU)) {
            return {
              "background-color": "#ffff00",
            };
          }
          if (morados.includes(rowItemEstadoU)) {
            return {
              "background-color": "#9900ff",
            };
          }
          if (moradoNuevo.includes(rowItemEstadoU)) {
            return {
              "background-color": "#c27ba0",
            };
          }
          if (serverNulo.includes(rowItemEstadoU)) {
            return {
              "background-color": "#e06666",
            };
          }
          if (verde.includes(rowItemEstadoU)) {
            return {
              "background-color": "#34a853",
            };
          }
          if (naranja.includes(rowItemEstadoU)) {
            return {
              "background-color": "#ff9900",
            };
          }
          if (azul.includes(rowItemEstadoU)) {
            return {
              "background-color": "#4285f4",
            };
          }
          break;
        case "Respuesta":
          var rowItemRespuesta = row["Respuesta"].trim();

          if (rojos.includes(rowItemRespuesta)) {
            return {
              "background-color": "#FF0000",
            };
          }
          if (amarillos.includes(rowItemRespuesta)) {
            return {
              "background-color": "#ffff00",
            };
          }
          if (morados.includes(rowItemRespuesta)) {
            return {
              "background-color": "#9900ff",
            };
          }
          if (moradoNuevo.includes(rowItemRespuesta)) {
            return {
              "background-color": "#c27ba0",
            };
          }
          if (serverNulo.includes(rowItemRespuesta)) {
            return {
              "background-color": "#e06666",
            };
          }
          if (verde.includes(rowItemRespuesta)) {
            return {
              "background-color": "#34a853",
            };
          }
          if (naranja.includes(rowItemRespuesta)) {
            return {
              "background-color": "#ff9900",
            };
          }
          if (azul.includes(rowItemRespuesta)) {
            return {
              "background-color": "#4285f4",
            };
          }
          break;
        case "Total":
          if (row.Total < 3) {
            return {
              "background-color": "#FF0000",
            };
          }
          break;
        default:
          break;
      }

      return {};
    },

    onSelect() {
      this.onSelected = infoSelected(this.$refs.grid, "_id");
      this.rigthClick = false;
    },

    getLabel(a) {
      this.getLabelColor(a);
      this.numeroCol = a.colPos;
      this.numeroRow = a.rowPos;
      var aver = a.cell.id.split("-")[a.cell.id.split("-").length - 1];
      if (!(aver[0] == "N" && aver[aver.length - 1] == "%")) {
        this.label_ = aver;
      }
    },

    myLabels(a) {
      console.log(a);
    },

    cambioEstadoU(newVal, oldVal, row) {
      var valorDeCelda = newVal.trim();
      this.textoActionToolBar.push(
        "Guardando estadoU " + '"' + valorDeCelda + '"'
      );
      console.log(oldVal);
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "agregar");
          fd.append("titulo", "Alerta");
          fd.append("materia", this.curso.materia);
          fd.append("periodo", this.curso.periodo);
          fd.append("tipoAlerta", "Alerta");
          fd.append("comentarios", valorDeCelda);
          fd.append("opcionesTipo", "");
          fd.append("estudianteId", row._id);
          axios.post(host + "/alertas", fd).then((result) => {
            let data = result.data.text;
            var rem = this.textoActionToolBar.indexOf(
              "Guardando estadoU " + '"' + valorDeCelda + '"'
            );
            if (data != "OK") {
              this.$alert(
                "Error al guardar el ajuste de estado de usuario, intentelo nuevamente"
              );
              this.textoActionToolBar.splice(rem, 1);
              this.textoActionToolBar.push(
                "Error al guardar estadoU " + '"' + valorDeCelda + '"'
              );
              return false;
            } else {
              this.textoActionToolBar.splice(rem, 1);
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    search(a) {
      if (a == "blur") {
        this.blur();
      } else {
        for (let y = 0; y < a.length; y++) {
          var element = a[y];
          if (element.label == "Materias") this.curso.materia = element.value;
        }
        if (element.label == "Periodos") {
          this.curso.periodo = element.value;
        }
      }
    },
    // Eventos
    eventosEstudiantes(d) {
      if (d == "refresh" || d == "edit") {
        this.buscar();
      }
      if (d == "clearText") {
        this.textoActionToolBar = [];
      }
    },

    toolBarFooter(a) {
      this.curso = a;
    },
  },
};
</script>


<!-- change the column label this.$refs.grid.fields.forEach((field) => {
  if (field.name === 'col23') field.label = 'Product'
  if (field.label === '') field.label = '(' + field.name + ')'
})
this.$forceUpdate()  -->
